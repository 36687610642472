const global = {
  BASE_API_URL:
    process.env.NODE_ENV !== 'development'
      ? 'https://api.moveoctane.co.za/v2'
      : 'https://api.moveoctane.co.za/v2',
  AUTH0_OCTANE_API_CLIENT_ID: 'baq49FPWUm2Hlpp7il1ZZ2eol6k3mFlP',
  AUTH0_REALM: 'octane-api',
  CALLBACK: 'https://moveoctane.co.za/callback',
  AUTH0_DOMAIN: 'autoboys.auth0.com',
};
export default global;
